import * as containerActions from '../../actions/container';
import { Button, Checkbox, Col, Input, Row, Select, Spin } from 'antd';
import { ajax, getSignModeIconType, countPadding, createNotificationShort } from '../../helper';
import {removeModal, toggleModal} from '../../actions/modal';
import CustomAlert from './../CustomAlert';
import PropTypes from 'prop-types';
import React from 'react';
import SignImage from './SignImage';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import UserProfileError from '../UserProfileError';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SIGN, SIGNATURE_POSITION } from '../../constants/modalNames';
import {
    SIGNING_TYPE_SIGN,
    TAB_KEY_DEFAULT_CONTAINERS
} from '../../constants/strings';
import {updateSelectedContainerList} from '../../actions/container';
import {WORKFLOW_MESSAGE_MAX_LENGTH} from "../../constants/config";
import MultiPagePreviewNavigation from "./MultiPagePreviewNavigation";

const { Option } = Select;

export class PdfPreview extends React.Component {
    constructor(props) {
        super(props);
        this.pdfDocPage = React.createRef();
        this.state = {
            activeKey: this.getActiveKey(),
            applyToEveryPDF: false,
            backgroundImage: '',
            data: '',
            deltaPosition: { x: 0, y: 0 },
            emptyImage: true,
            error: null,
            fittedPDFSizeX: 0,
            fittedPDFSizeY: 0,
            get resize() {
                return Math.floor((this.width / props.pdfSizeX) * 100);
            },
            hasResizeState: false,
            height: 0,
            initialPDFSizeX: 0,
            initialPDFSizeY: 0,
            loading: true,
            maxRelativePosition: { x: 0, y: 0 },
            pageNumber: 1,
            pdfReason: '',
            pdfReasonLength: 0,
            pdfResizeCounter: 0,
            pdfSizeX: 0,
            pdfSizeY: 0,
            relativePosition: { x: 0, y: 0 },
            selectedOption: null,
            signSchemas: [],
            userProfileError: false,
            width: 0,
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadPdfPage(this.props.container.id, 1);
        if (this.props.setSignaturePosition) {
            this.loadSchemas();
        }
        const height = this.pdfDocPage.current.clientHeight;
        const width = this.pdfDocPage.current.clientWidth;
        this._isMounted && this.setState({ parentHeight: height, parentWidth: width });
        this.setRelative();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onDragStop = (e, d) => {
        this._isMounted &&
            this.setState({
                deltaPosition: {
                    x: d.x,
                    y: d.y,
                },
            });
        this.setRelative();
    };

    setRelative = () => {
        this._isMounted &&
            this.setState({
                relativePosition: {
                    x: this.state.deltaPosition.x === 0 ? 0 : this.state.deltaPosition.x / this.state.pdfSizeX,
                    y: this.state.deltaPosition.y === 0 ? 0 : this.state.deltaPosition.y / this.state.pdfSizeY,
                },
            });
    };

    setMaxRelative = (pdfSizeX, pdfSizeY) => {
        this._isMounted &&
            this.setState({
                maxRelativePosition: {
                    x: (pdfSizeX - this.state.width) / pdfSizeX,
                    y: (pdfSizeY - this.state.height) / pdfSizeY,
                },
            });
    };

    recountRelative = (pdfSizeX, pdfSizeY) => {
        let newMaxRelativeX = pdfSizeX - this.state.width === 0 ? 0 : (pdfSizeX - this.state.width) / pdfSizeX;
        let newMaxRelativeY = pdfSizeY - this.state.height === 0 ? 0 : (pdfSizeY - this.state.height) / pdfSizeY;
        let recountedRelativeX =
            this.state.relativePosition.x *
            (newMaxRelativeX === 0 ? 0 : newMaxRelativeX / this.state.maxRelativePosition.x);
        let recountedRelativeY =
            this.state.relativePosition.y *
            (newMaxRelativeY === 0 ? 0 : newMaxRelativeY / this.state.maxRelativePosition.y);
        this._isMounted &&
            this.setState({
                relativePosition: {
                    x: recountedRelativeX,
                    y: recountedRelativeY,
                },
                maxRelativePosition: {
                    x: newMaxRelativeX,
                    y: newMaxRelativeY,
                },
            });
    };

    recountDeltaPosition = (pdfSizeX, pdfSizeY) => {
        this._isMounted &&
            this.setState({
                deltaPosition: {
                    x: pdfSizeX * this.state.relativePosition.x,
                    y: pdfSizeY * this.state.relativePosition.y,
                },
            });
    };

    // eslint-disable-next-line no-unused-vars
    onResize = (e, dir, ref) => {
        let resizedWidth = +ref.style.width.replace('px', '');
        let resizedHeight = +ref.style.height.replace('px', '');
        this._isMounted &&
            this.setState({
                width: resizedWidth,
                height: resizedHeight,
                resize: Math.floor((resizedWidth / this.state.initialPDFSizeX) * 100),
            });
    };

    setSelectedSchema = async (selectedOption) => {
        const isSelectable = this.state.signSchemas.length > 3 && localStorage.getItem('customSignatureImage') === 'false';
        this._isMounted && this.setState({ userProfileError: isSelectable });
        if (selectedOption.profileName === 'empty') {
            this._isMounted && this.setState({ emptyImage: true, selectedOption: selectedOption });
        } else {
            let base64Image;
            await ajax()
                .get(`/signatureImage/${selectedOption.id}`)
                .then((response) => {
                    base64Image = `data:image/png;base64,${response.data.image}`;
                })
                .catch(async () => {
                    await ajax()
                        .get(`/signatureImage/1`)
                        .then((response) => {
                            base64Image = `data:image/png;base64,${response.data.image}`;
                        })
                        .catch(() => {});
                });
            this._isMounted &&
                this.setState({
                    backgroundImage: base64Image,
                    deltaPosition: {
                        x: selectedOption.x,
                        y: selectedOption.y,
                    },
                    width: selectedOption.width,
                    height: selectedOption.height,
                    selectedOption: selectedOption,
                    resize: Math.floor((selectedOption.width / this.state.initialPDFSizeX) * 100),
                    emptyImage: false,
                });
        }
    };

    loadSchemas = async () => {
        await ajax()
            .get('/signatureProfiles')
            .then(({ data }) => {
                this._isMounted && this.setState({ signSchemas: data });
            })
            .catch((err) => console.error(err));
        let schema = this.state.signSchemas.filter((s) => s.isDefault);
        this.setSelectedSchema(schema[0]);
    };

    loadPdfPage(id, pageNumber) {
        this._isMounted && this.setState({ loading: true });
        ajax()
            .get(`${window.config.REACT_APP_SERVICE_BASE_URL}/containers/${id}/preview?pageNumber=${pageNumber}`)
            .then((data) => {
                const resizeNeeded =
                    data.data.imageWidth > this.state.parentWidth - 30 && !this.props.setSignaturePosition;
                const rateToFitToScreen = (this.state.parentWidth - 30) / data.data.imageWidth;
                const fittedPDFSizeX =
                    resizeNeeded && this.props.modal.visible ? this.state.parentWidth - 30 : data.data.imageWidth;
                const fittedPDFSizeY =
                    resizeNeeded && this.props.modal.visible
                        ? data.data.imageHeight * rateToFitToScreen
                        : data.data.imageHeight;
                if (this.state.maxRelativePosition.y !== 0) {
                    this.recountRelative(data.data.imageWidth, data.data.imageHeight);
                } else {
                    this.setMaxRelative(data.data.imageWidth, data.data.imageHeight);
                    this.recountRelative(data.data.imageWidth, data.data.imageHeight);
                }
                this.recountDeltaPosition(data.data.imageWidth, data.data.imageHeight);
                this._isMounted &&
                    this.setState({
                        loading: false,
                        data: `data:image/jpeg;base64,${data.data.image}`,
                        initialPDFSizeX: data.data.imageWidth,
                        initialPDFSizeY: data.data.imageHeight,
                        fittedPDFSizeX: fittedPDFSizeX,
                        fittedPDFSizeY: fittedPDFSizeY,
                        pdfSizeX:
                            this.state.pdfResizeCounter === 0
                                ? fittedPDFSizeX
                                : fittedPDFSizeX + this.state.pdfResizeCounter * (data.data.imageWidth / 10),
                        pdfSizeY:
                            this.state.pdfResizeCounter === 0
                                ? fittedPDFSizeY
                                : fittedPDFSizeY + this.state.pdfResizeCounter * (data.data.imageHeight / 10),
                    });
            })
            .catch(() => {
                this._isMounted && this.setState({ loading: false });
            });
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.container.id !== this.props.container.id) {
            if (nextProps.container.type === 'pdf') {
                this._isMounted &&
                    this.setState({
                        pageNumber: 1,
                        pdfResizeCounter: 0,
                    });
                this.loadPdfPage(nextProps.container.id, 1);
            }
        } else {
            if (nextState.pageNumber !== this.state.pageNumber) {
                 this.loadPdfPage(nextProps.container.id, nextState.pageNumber);
            }
        }
        return true;
    }

    goToPage = (pageNumber) => {
        this.setState({pageNumber: pageNumber})
        this.recountDeltaPosition();
        this.loadPdfPage(this.props.container.id, pageNumber);
    };

    // We need this, because the rounding for the percent value could lose us about 6 pixels, which means the e-Szigno could not sign the PDF file.
    countPadding(position, size) {
        const realPadding = ((position * 100) / size).toFixed(0);
        if (size === 0) {
            return 0;
        }
        if (realPadding - 1 < 0) {
            return 0;
        } else {
            if (realPadding > 93) {
                return 93;
            } else {
                return realPadding;
            }
        }
    }

    countPaddingX(position) {
        return countPadding(position, this.state.initialPDFSizeX);
    }

    countPaddingY(position) {
        return countPadding(position, this.state.initialPDFSizeY);
    }

    continueSigning = () => {
        const { pageNumber, deltaPosition, applyToEveryPDF } = this.state;
        const {
            showSignModal,
            hideSignModal,
            container,
            currentTab,
            cegjegyzekSzam,
            updateContainers,
            previewContainerList,
            showSignaturePositioning,
            signModalData,
            attributeRole,
            certificate,
            updatePdfSignatures
        } = this.props;
        const pdfImagePageToSet = pageNumber === container.pages && container.pages > 1 ? -1 : pageNumber;
        hideSignModal();

        let paddingX = this.countPaddingX(deltaPosition.x <= 0 ? 0 : deltaPosition.x);
        let paddingY = this.countPaddingY(deltaPosition.y <= 0 ? 0 : deltaPosition.y);
        const pdfSettings = {
            pdfImagePaddingX: paddingX,
            pdfImagePaddingY: paddingY,
            pdfImagePage: pdfImagePageToSet,
            pdfImageResize: Math.floor((this.state.width / this.state.initialPDFSizeX) * 100),
            signatureProfileId: this.state.selectedOption ? this.state.selectedOption.id : null,
            pdfReason: this.state.pdfReason,
        };
        signModalData.push({
            containerId: container.id,
            pdfSettings,
        });
        const pdfPreviewContainerList = previewContainerList.filter((e) => e.isPdf).filter(
            (e) => signModalData.map((data) => data.containerId).indexOf(e.container.id) === -1
        );

        if (applyToEveryPDF) {
            pdfPreviewContainerList.forEach(({ container }) => {
                signModalData.push({
                    containerId: container.id,
                    pdfSettings,
                });
            });
        }

        if (pdfPreviewContainerList.length > 0 && !applyToEveryPDF) {
            containerActions.getContainerWithPages(pdfPreviewContainerList[0].container.id).then(pdfPreviewContainer =>
                showSignaturePositioning({
                    cegjegyzekSzam,
                    container: pdfPreviewContainer,
                    currentTab,
                    previewContainerList: previewContainerList,
                    signModalData: signModalData,
                    certificate: certificate,
                    attributeRole: attributeRole,
                    signingType: SIGNING_TYPE_SIGN
                }));
        } else {
            showSignModal({
                containerIds:
                    previewContainerList.length > 0 ? previewContainerList.map((p) => p.container.id) : [container.id],
                ack: false,
                cegjegyzekSzam,
                previewSchemaId: this.state.selectedOption
                    ? this.state.selectedOption.extension === 'png'
                        ? this.state.selectedOption.id
                        : 1
                    : null,
                signModalData: signModalData,
                certificate: certificate,
                attributeRole: attributeRole,
                signingType: SIGNING_TYPE_SIGN,
                onFinished: (result) => {
                    if (result.succeeded) {
                        hideSignModal();
                        updateContainers();
                        updatePdfSignatures(container);
                    } else {
                        // Ha a felhasználó megszakítja az aláírást, vagy ha a szerver valamilyen ok miatt megszakítja
                        createNotificationShort(this.props.translate, {
                            message: this.props.translate('errorCodes.SIGNING_PROCESS_INTERRUPTED'),
                            type: 'error',
                        });
                        hideSignModal();
                    }
                }
            });
        }
    };

    handleChangeSchema = (value) => {
        let selectedSchemaId = parseInt(value);
        let schemas = this.state.signSchemas;
        for (let i = 0; i < schemas.length; i++) {
            let schema = schemas[i];
            if (schema.id === selectedSchemaId) {
                this.setSelectedSchema(schema);
                return;
            }
        }
    };

    translateSchemaName = (schema) => {
        if (schema.id === 1) {
            return this.props.translate('default.sign');
        }
        if (schema.id === 2) {
            return this.props.translate('invisible.sign');
        }
        return schema.profileName;
    };

    setDefaultSchema = () => {
        let selectedSchema = this.state.selectedOption;
        if (selectedSchema) {
            return this.translateSchemaName(selectedSchema);
        }
        let schemas = this.state.signSchemas;
        for (let i = 0; i < schemas.length; i++) {
            let schema = schemas[i];
            if (schema.isDefault) {
                return this.translateSchemaName(schema);
            }
        }
        return null;
    };

    saveIntentionToUseSignatureReason = () => {
        if (
            localStorage.getItem('useSignatureReason') === null ||
            localStorage.getItem('useSignatureReason') === 'no'
        ) {
            localStorage.setItem('useSignatureReason', 'yes');
            this._isMounted && this.setState({ activeKey: ['1'] });
        } else {
            localStorage.setItem('useSignatureReason', 'no');
            this._isMounted && this.setState({ activeKey: [] });
        }
    };

    getActiveKey = () => {
        if (
            localStorage.getItem('useSignatureReason') === null ||
            localStorage.getItem('useSignatureReason') === 'no'
        ) {
            return [];
        } else {
            return ['1'];
        }
    };

    handleZoomIn = () => {
        const toAddX = Math.floor(this.state.fittedPDFSizeX / 10);
        const toAddY = Math.floor(this.state.fittedPDFSizeY / 10);
        this._isMounted &&
            this.setState({
                pdfSizeX: this.state.pdfSizeX + toAddX,
                pdfSizeY: this.state.pdfSizeY + toAddY,
                pdfResizeCounter: this.state.pdfResizeCounter + 1,
            });
    };

    handleZoomOut = () => {
        const toSubtractX = Math.floor(this.state.fittedPDFSizeX / 10);
        const toSubtractY = Math.floor(this.state.fittedPDFSizeY / 10);
        this._isMounted &&
            this.setState({
                pdfSizeX: this.state.pdfSizeX - toSubtractX,
                pdfSizeY: this.state.pdfSizeY - toSubtractY,
                pdfResizeCounter: this.state.pdfResizeCounter - 1,
            });
    };

    handleResetZoom = () => {
        this._isMounted &&
            this.setState({
                pdfSizeX: this.state.fittedPDFSizeX,
                pdfSizeY: this.state.fittedPDFSizeY,
                pdfResizeCounter: 0,
            });
    };

    render() {
        const { loading, data, pageNumber, error, signSchemas, selectedOption } = this.state;
        const { setSignaturePosition, container, translate, previewContainerList, signImagePreviewMinWidth, signImagePreviewMaxWidth, signatureTextProps } = this.props;
        const imgStyle = { height: this.state.pdfSizeY, width: this.state.pdfSizeX };
        const pdfDocPageStyleForModal = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            borderRadius: '4px',
            left: '+7px',
            position: 'relative',
        };
        const pdfDocPageStyle = {
            display: 'table',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            borderRadius: '4px',
            left: '+7px',
            position: 'relative',
            boxShadow: 'rgba(0, 0, 0, 0.09) 0 2px 8px 1px',
            border: '1px solid rgba(0, 0, 0, 0.09)',
        };

        const pdfWrapperStyle = {
            overflow: 'auto',
            paddingTop: '8px',
            paddingBottom: '8px',
        };
        const styleToUseForDocPage = this.props.modal.visible ? pdfDocPageStyle : pdfDocPageStyleForModal;
        const styleToUseForWrapper = this.props.modal.visible ? pdfWrapperStyle : {};
        const SelectElement = () => {
            return (
                <Select
                    style={{ width: 200 }}
                    onSelect={this.handleChangeSchema}
                    defaultValue={this.setDefaultSchema()}
                >
                    {signSchemas
                        ? signSchemas.map((schema) => (
                              <Option key={schema.id}>{this.translateSchemaName(schema)}</Option>
                          ))
                        : null}
                </Select>
            );
        };
        return (
            <div className={'pdf-doc-container'}>
                {setSignaturePosition && (
                    <Row>
                        <Col span={6} style={{ fontWeight: 'bold', paddingTop: '8px' }}>
                            {translate('signature.image.title.reason') + ':'}
                        </Col>
                        <Col span={18}>
                            <Input
                                placeholder={translate('signature.image.title.reason')}
                                value={this.state.pdfReason}
                                onChange={(e) =>
                                    this.setState({
                                        pdfReason: e.target.value,
                                        pdfReasonLength: e.target.value.length,
                                    })
                                }
                                maxLength={WORKFLOW_MESSAGE_MAX_LENGTH}
                            />
                            {this.state.pdfReasonLength >= WORKFLOW_MESSAGE_MAX_LENGTH && (
                                <p style={{ color: 'red' }}>{translate('reason.length')}</p>
                            )}
                        </Col>
                    </Row>
                )}
                {error ? (
                    <CustomAlert error={error} />
                ) : (
                    <div style={{ paddingTop: '8px' }}>
                        <nav
                            style={{
                                textAlign: 'center',
                                padding: 16,
                                marginBottom: 16,
                                backgroundColor: '#FAFAFA',
                                border: '1px solid #e8e8e8',
                            }}
                        >
                            <Row style={{ textAlign: 'left' }}>
                                {setSignaturePosition && previewContainerList.length > 0 && (
                                    <Checkbox onChange={(e) => this.setState({ applyToEveryPDF: e.target.checked })}>
                                        {' '}
                                        {translate('ckeckbox.signimage.text')}
                                    </Checkbox>
                                )}
                            </Row>
                            <Row>
                                <Col span={7} style={{ textAlign: 'left' }}>
                                    {setSignaturePosition && <SelectElement />}
                                </Col>
                                <Col span={10}>
                                    <MultiPagePreviewNavigation
                                        numberOfPages={container.pages}
                                        pageNumber={pageNumber}
                                        goToPageCallback={this.goToPage}
                                        loading={loading}>
                                    </MultiPagePreviewNavigation>
                                </Col>
                                {!this.props.modal.visible && (
                                    <Col span={4}>
                                        <Button
                                            style={{ marginRight: 10 }}
                                            title={translate('button.zoom.in')}
                                            type={'primary'}
                                            onClick={this.handleZoomIn}
                                            icon={'zoom-in'}
                                        />
                                        <span />
                                        <Button
                                            style={{ marginRight: 10 }}
                                            title={translate('button.zoom.out')}
                                            type={'primary'}
                                            onClick={this.handleZoomOut}
                                            icon={'zoom-out'}
                                        />
                                        <Button
                                            title={translate('button.zoom.reset')}
                                            type={'primary'}
                                            onClick={this.handleResetZoom}
                                            icon={'undo'}
                                        />
                                    </Col>
                                )}
                                <Col span={7} style={{ textAlign: 'right' }}>
                                    {setSignaturePosition && (
                                        <Button
                                            disabled={this.state.userProfileError}
                                            type='primary'
                                            icon={getSignModeIconType()}
                                            onClick={() => {
                                                this.continueSigning();
                                            }}
                                        >
                                            {translate('buttons.sign.text')}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                            {this.state.userProfileError && (
                                <Row style={{ paddingTop: '8px' }}>
                                    <UserProfileError size={64} icon='user' />
                                </Row>
                            )}
                        </nav>
                        <Spin spinning={loading}>
                            <div className='pdf-doc-page-wrapper' style={styleToUseForWrapper}>
                                <div className='pdf-doc-page' style={styleToUseForDocPage} ref={this.pdfDocPage}>
                                    {setSignaturePosition && !this.state.emptyImage && (
                                        <SignImage
                                            props={{
                                                width: this.state.width,
                                                height: this.state.height,
                                                deltaPosition: this.state.deltaPosition,
                                                minConstraints: [signImagePreviewMinWidth, this.state.height * (signImagePreviewMinWidth / this.state.width)],
                                                maxConstraints: [signImagePreviewMaxWidth, this.state.height * (signImagePreviewMaxWidth / this.state.width)],
                                                onDragStop: this.onDragStop,
                                                onResize: this.onResize,
                                                namePosition: selectedOption.hasPdfSignTitle,
                                                datePosition: selectedOption.hasPdfSignTitleDate,
                                                fontSize: Math.floor((this.state.width / imgStyle.width) * signatureTextProps.fontSizeMultiplier),
                                                signatureTextProps: signatureTextProps
                                            }}
                                            backgroundImage={this.state.backgroundImage}
                                        />
                                    )}
                                    {!this.props.modal.visible ? (
                                        <ScrollContainer
                                            className='scroll-container'
                                            nativeMobileScroll={false}
                                            hideScrollbars={false}
                                        >
                                            <img style={imgStyle} src={data} alt={'PDF document'} />
                                        </ScrollContainer>
                                    ) : (
                                        <img style={imgStyle} src={data} alt={'PDF document'} draggable='false' />
                                    )}
                                </div>
                            </div>
                        </Spin>
                    </div>
                )}
            </div>
        );
    }
}

PdfPreview.propTypes = {
    attributeRole: PropTypes.object,
    cegjegyzekSzam: PropTypes.string,
    container: PropTypes.object.isRequired,
    containersInSignatureProcess: PropTypes.array,
    createSignatureSchemaModal: PropTypes.func,
    currentTab: PropTypes.string,
    hideSignModal: PropTypes.func.isRequired,
    modal: PropTypes.object,
    pdfSizeX: PropTypes.number.isRequired,
    pdfSizeY: PropTypes.number.isRequired,
    previewContainerList: PropTypes.array,
    selectedOption: PropTypes.object,
    setSignaturePosition: PropTypes.bool.isRequired,
    showSignModal: PropTypes.func.isRequired,
    showSignaturePositioning: PropTypes.func,
    signModalData: PropTypes.array,
    signaturesNumber: PropTypes.number.isRequired,
    translate: PropTypes.func.isRequired,
    updateContainers: PropTypes.func.isRequired,
    updatePdfSignatures: PropTypes.func.isRequired,
    signImagePreviewMinWidth: PropTypes.number.isRequired,
    signImagePreviewMaxWidth: PropTypes.number.isRequired,
    signatureTextProps: PropTypes.object.isRequired
};

PdfPreview.defaultProps = {
    currentTab: TAB_KEY_DEFAULT_CONTAINERS,
    pdfSizeX: 594,
    pdfSizeY: 841,
    setSignaturePosition: false,
};

const mapStateToProps = (state) => {
    return {
        containersInSignatureProcess: state.containerList.containersInSignatureProcess,
        modal: state.modal,
        signaturesNumber: state.containerList.signatures.length,
        translate: getTranslate(state.locale),
        currentTab: state.tabs.current
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showSignaturePositioning: (data) => {
            dispatch(removeModal());
            dispatch(toggleModal(SIGNATURE_POSITION, data));
        },
        showSignModal: (data) => {
            dispatch(toggleModal(SIGN, data));
        },
        hideSignModal: () => {
            dispatch(removeModal());
        },

        updatePdfSignatures: (container) => {
            dispatch(containerActions.getContainerData(container.id)).then((response) => {
                dispatch(updateSelectedContainerList([response]));
            })
            dispatch(containerActions.getContainerSignatures(container.type, container.id));
        },

        updateContainers: () => {
            dispatch(containerActions.getContainers()).then(() => {
                    dispatch(containerActions.getContainersInSignatureProcess()).then(() => {
                    });
                }
            );
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PdfPreview);
