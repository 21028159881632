/* eslint-disable no-case-declarations */
import initialState from './initialState';
import update from 'immutability-helper';
import * as types from '../actions/actionTypes';
import {UPDATE_CONTAINER_START} from '../actions/actionTypes';

export default function (state = initialState.containerList, action) {
    switch (action.type) {
        case types.ARCHIVE_SIGNATURE_SUCCESS:
            let archiveOldSignatures = state.signatures;
            let archiveNewSignatures = [];
            for (let index in archiveOldSignatures) {
                let signature = archiveOldSignatures[index];
                if (signature.id === action.payload.data.id) {
                    archiveNewSignatures.push({ data: action.payload.data });
                    continue;
                }
                archiveNewSignatures.push(signature);
            }
            return { ...state, signatures: archiveNewSignatures };
        case types.DELETE_SIGNATURE_SUCCESS:
            let oldSignatures = state.signatures;
            let newSignatures = [];
            for (let index in oldSignatures) {
                let signature = oldSignatures[index];
                if (signature.id === action.payload) {
                    continue;
                }
                newSignatures.push(signature);
            }
            return { ...state, signatures: newSignatures };
        case types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_SUCCESS:
            let containersSP = action.payload.containers;
            let selectedContainersSP = state.selectedContainers;
            if (selectedContainersSP.length === 0 && containersSP.length > 0) {
                selectedContainersSP = [containersSP[0]];
            }
            return { ...state,
                containersInSignatureProcess: containersSP,
                selectedContainers: selectedContainersSP,
                totalElementsSP: action.payload.count,
                totalElementsWithoutFilterSP: action.payload.totalReceivedCount,
                containerSPExist: action.payload.count > 0,
                filterSP: action.payload.filter,
                pageSP: Math.floor(action.payload.offset / action.payload.limit) + 1
            };
        case types.GET_CONTAINERS_IN_SIGNATURE_PROCESS_FAILURE:
            return { ...state, containersInSignatureProcess: [] };
        case types.GET_CONTAINERS_SUCCESS:
            let containers = action.payload.containers;
            let oldContainers = state.containers;

            return {
                ...state,
                containers: containers,
                totalElements: action.payload.count,
                containerExist: action.payload.count > 0,
                filter: action.payload.filter,
                page: Math.floor(action.payload.offset / action.payload.limit) + 1,
                containersChangeCounter: JSON.stringify(oldContainers) !== JSON.stringify(containers) ? increment(state.containersChangeCounter) : state.containersChangeCounter
                };
        case types.GET_CONTAINERS_FAILURE:
            return {
                ...state,
                containers: [],
                signatures: []
            };
        case types.GET_CONTAINER_SIGNATURES_SUCCESS:
            let signatures = action.payload;
            let selected = signatures.find(element => element.id === state.selectedSignature.id);
            return { ...state, signatures: action.payload, selectedSignature: selected ? selected : {} };
        case types.GET_CONTAINER_SIGNATURES_FAILURE:
            return { ...state, signatures: [] };
        case types.VALIDATE_SIGNATURE_SUCCESS:
            let Signatures = state.signatures;
            for (let index in Signatures) {
                let signature = Signatures[index];
                if (signature.id === action.payload.id) {
                    return update(state, {
                        signatures: {
                            [index]: {
                                verified: { $set: true }
                            }
                        }
                    });
                }
            }
            return { ...state };
        case types.VALIDATE_SIGNATURE_FAILURE:
            for (let index in state.signatures) {
                let signature = state.signatures[index];
                if (signature.id === action.payload.sig.id) {
                    return update(state, {
                        signatures: {
                            [index]: {
                                verified: { $set: false }
                            }
                        }
                    });
                }
            }
            return { ...state };
        case types.RENAME_CONTAINER_SUCCESS:
            for (let i in state.containers) {
                let container = state.containers[i];
                if (container.data.id === action.index) {
                    return update(state, {
                        containers: {
                            [i]: {
                                data: {
                                    fileBaseName: { $set: action.title }
                                }
                            }
                        }
                    });
                }
            }
            break;
        case types.RESET_CONTAINER_LIST:
            return {
                ...state,
                containers: [],
                signatures: [],
                toggleModal: false,
                selectedContainers: [],
                containersInSignatureProcess: [],
                storedFileIdForDownload: '',
                waitingToSignByOthers: []
            };
        case types.SET_SELECTED_CONTAINERS:
            return {
                ...state,
                selectedContainers: action.payload
            };
        case types.ADD_SELECTED_CONTAINERS:
            let selectedContainers = [].concat(state.selectedContainers, action.payload);
            let selectedContainersIdSet = selectedContainers.map((c) => c.data.id);
            return {
                ...state,
                selectedContainers: selectedContainers.filter((value, index, self) => selectedContainersIdSet.indexOf(value.data.id) === index)
            };
        case types.REMOVE_SELECTED_CONTAINERS:
            return {
                ...state,
                selectedContainers: state.selectedContainers.filter((v) => action.payload.map(c => c.data.id).indexOf(v.data.id) < 0)
            };
        case types.UPDATE_SELECTED_CONTAINER_IDS:
            return {
                ...state,
                selectedContainers: state.containers.concat(state.containersInSignatureProcess).filter(c => action.payload.map(e => e.data.id).includes(c.data.id))
            };
        case UPDATE_CONTAINER_START:
            return {
                ...state,
                updatedContainer: undefined
            }
        case types.UPDATE_CONTAINER:
            if (!action.payload) {
                return state;
            }
            let updatedContainer = action.payload;
            return {
                ...state,
                updatedContainer: updatedContainer,
                containers: state.containers.map((container) => {
                    return container.data.id === updatedContainer.data.id ? updatedContainer : container;
                })
            };
        case types.RESET_SELECTED_CONTAINER_IDS:
            return {
                ...state,
                selectedContainers: []
            };
        case types.SELECT_ALL_CONTAINER:
            return {
                ...state,
                selectedContainers: action.payload
            };
        case types.STORE_FILE_ID:
            return {
                ...state,
                storedFileIdForDownload: action.payload
            };
        case types.DELETE_STORED_FILE_ID:
            return {
                ...state,
                storedFileIdForDownload: ''
            };
        case types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS:
            return {
                ...state,
                waitingToSignByOthers: action.payload
            };
        case types.UPDATE_WAITING_TO_BE_SIGNED_BY_OTHERS_BY_CONTAINER:
            const updated = action.payload;
            const tempWaitingToSignByOthers = state.waitingToSignByOthers.map((object) => {
                if (object.guid === updated.guid) {
                    return { ...updated };
                } else {
                    return object;
                }
            });
            return {
                ...state,
                waitingToSignByOthers: tempWaitingToSignByOthers
            };
        case types.NEW_CONTAINER_SUCCESS:
        case types.UPLOAD_CONTAINER_SUCCESS:
            return {
                ...state,
                containerExist: true,
                containersChangeCounter: increment(state.containersChangeCounter)
            };
        case types.DELETE_MULTIPLE_CONTAINERS_SUCCESS:
            return {
                ...state,
                selectedContainers: [],
                containersChangeCounter: increment(state.containersChangeCounter)
            };
        case types.UPDATE_SELECTED_SIGNATURE:
            return { ...state, selectedSignature: action.payload };
        case types.RESET_SELECTED_SIGNATURE:
            return { ...state, selectedSignature: {} };
        default:
            return state;
    }

    function increment(v) {
        return v === undefined ? 1 : v + 1;
    }
}
